<template>
  <div class="env-component-configuration mt-n6">
    <CyAlert
      v-if="!_.isEmpty(errors)"
      class="mt-4"
      theme="error"
      :content="errors"/>

    <CyAlert
      v-if="!canEdit"
      theme="info"
      :title="$t('readOnlyNotification.title')"
      :content="$t('readOnlyNotification.text')"/>

    <div
      v-if="pageLoading"
      class="loading-spinner">
      <v-progress-circular
        indeterminate
        color="secondary"/>
    </div>

    <template v-else>
      <CyWizardStackForms
        v-if="_.isEmpty(missingUsecaseErrors)"
        v-model="rawFormsConfig"
        class="env-component-configuration__stackforms--margin-correction"
        :disabled="!canEdit || updating"
        :forms="formsConfigCurrent"
        :stack-ref="stack.ref"
        :environment-canonical="envCanonical"
        :component-canonical="componentCanonical"
        :use-case="componentUseCase"
        @validate="setConfigurationValidity(envCanonical, $event)"/>

      <div class="env-component-configuration__actions d-flex align-center space-x-4">
        <CyButton
          class="ml-auto"
          theme="primary"
          variant="secondary"
          :disabled="updating || !hasUnsavedChanges"
          @click="resetConfig()">
          {{ $t('forms.btnCancel') }}
        </CyButton>
        <CyButton
          theme="secondary"
          :loading="updating"
          :disabled="isSaveBtnDisabled"
          icon="done"
          @click="$toggle.showStackFormsDiff(true)">
          {{ $t('forms.reviewAndSave') }}...
        </CyButton>
      </div>
    </template>

    <CyModal
      v-if="showStackFormsDiff"
      :header-title="$t('stackFormsDiffDialog.title')"
      :action-btn-func="pushComponentConfig"
      :action-btn-text="$t('stackFormsDiffDialog.actionBtnText')"
      :cancel-btn-func="() => $toggle.showStackFormsDiff(false)"
      :cancel-btn-text="$t('forms.btnCancel')"
      dialog-class="v-dialog--medium"
      :loading="updating"
      modal-type="info"
      large>
      <template slot="default">
        <CyStackFormsDiff
          :vars="{
            old: oldVariables,
            new: rawFormsConfig,
          }"/>
      </template>
    </CyModal>

    <CyModal
      v-if="showConfirmCloseDialog"
      :header-title="$t('confirmCloseDialog.title')"
      :action-btn-func="exitRoute"
      :action-btn-text="$t('confirmCloseDialog.actionBtnText')"
      :cancel-btn-func="stayOnPage"
      :cancel-btn-text="$t('confirmCloseDialog.cancelBtnText')"
      modal-type="warning"
      small>
      <template slot="default">
        <p class="ma-0">
          {{ $t('confirmCloseDialog.text') }}
        </p>
      </template>
    </CyModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import CyStackFormsDiff from '@/components/CyStackFormsDiff.vue'
import CyWizardStackForms from '@/components/CyWizardStackForms.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageEnvComponentConfiguration',
  components: {
    CyStackFormsDiff,
    CyWizardStackForms,
  },
  breadcrumb () {
    const { projectCanonical, projectName, envCanonical, componentCanonical } = this

    return constructBreadcrumb(this.$options.name, componentCanonical, [
      {
        label: this.$t('Components'),
        name: 'environmentOverview',
        params: { projectCanonical, envCanonical },
      },
      {
        label: envCanonical,
        name: 'environment',
        params: { projectCanonical, envCanonical },
      },
      {
        label: this.$t('Environments'),
        name: 'projectOverview',
        params: { projectCanonical },
      },
      {
        label: projectName,
        name: 'project',
        params: { projectCanonical },
      },
      {
        label: this.$t('routes.projectsSection'),
        name: 'projectsSection',
      },
    ])
  },
  beforeRouteLeave (to, from, next) {
    const userCanLeave = () => !this.hasUnsavedChanges || !_.isEmpty(this.to)

    const pipelineCanonical = `${this.projectCanonical}-${this.envCanonical}-${this.componentCanonical}`
    const pipelineCanonicalNeedsCorrection = to.name === 'envComponentPipeline' && to.params.pipelineCanonical !== pipelineCanonical

    if (pipelineCanonicalNeedsCorrection) {
      next({ ...to, params: { ...to.params, pipelineCanonical } })
    }

    if (userCanLeave()) return next()

    this.to = to
    this.showConfirmCloseDialog = true
  },
  props: {
    projectCanonical: {
      type: String,
      default: '',
    },
    envCanonical: {
      type: String,
      default: '',
    },
    componentCanonical: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    formsConfigCurrent: {},
    isConfigurationValid: {},
    pageLoading: true,
    updating: false,
    rawFormsConfig: {},
    showConfirmCloseDialog: false,
    showStackFormsDiff: false,
    missingUsecaseErrors: [],
    to: null,
  }),
  computed: {
    ...mapState('organization/project', {
      environmentsConfigErrors: (state) => state.errors.environmentsConfig,
      formsErrors: (state) => state.errors.forms,
      pipelineErrors: (state) => state.errors.pipelines,
      projectErrors: (state) => state.errors.project,
      projectPipelines: (state) => state.pipelines,
    }),
    ...mapState('organization/stack', {
      stackConfig: (state) => state.config,
    }),
    ...mapState('organization/project/configRepository', {
      configRepositoryErrors: (state) => state.errors.configRepository,
    }),
    ...mapState('organization/quota', {
      debouncingQuotas: (state) => state.debouncing,
    }),
    ...mapGetters('organization/project', [
      'project',
      'envComponent',
      'envComponentConfig',
    ]),
    ...mapGetters('organization/quota', {
      isEnvQuotaValid: 'isEnvValid',
    }),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    component () {
      return this.envComponent(this.envCanonical, this.componentCanonical)
    },
    canEdit () {
      return this.$cycloid.permissions.canDisplay('UpdateProject', this.project.canonical)
    },
    canSave () {
      const { envCanonical, isConfigurationValid, quotasEnabled } = this
      const areQuotasValid = !quotasEnabled || (this.isEnvQuotaValid(envCanonical) && !this.debouncingQuotas[envCanonical])

      return isConfigurationValid[envCanonical] && areQuotasValid
    },
    componentUseCase () {
      return this.component.use_case
    },
    errors () {
      return [
        ...this.environmentsConfigErrors,
        ...this.missingUsecaseErrors,
        ...this.configRepositoryErrors,
        ...this.formsErrors,
        ...this.projectErrors,
        ...this.pipelineErrors,
      ]
    },
    formsConfig () {
      return this.envComponentConfig(this.envCanonical, this.componentCanonical) || {}
    },
    hasUnsavedChanges () {
      const { formsConfigCurrent, rawFormsConfig } = this

      if (_.isEmpty(rawFormsConfig)) return false
      let atLeastOneHasDiff = false

      const currentConfig = _.cloneDeep(formsConfigCurrent)
      if (_.isEmpty(currentConfig)) return false

      currentConfig.sections.forEach((section) => {
        section.groups.forEach((group) => {
          group.vars.forEach(({ key, default: defaultValue, current: storedValue, widget }) => {
            const emittedValue = rawFormsConfig[section.name]?.[group.name]?.[key] ?? null
            const isInventoryWidget = widget === 'cy_inventory_resource'
            if (isInventoryWidget && _.isUndefined(storedValue)) storedValue = null

            const isNewInForms = storedValue === undefined

            const existedAndHasDiff = !isNewInForms && (emittedValue !== null || isInventoryWidget) && !_.isEqual(emittedValue, storedValue)
            const isNewButDiffersFromDefault = isNewInForms && (emittedValue !== null || isInventoryWidget) && !_.isEqual(emittedValue, defaultValue)
            if (existedAndHasDiff || isNewButDiffersFromDefault) atLeastOneHasDiff = true
          })
        })
      })

      return atLeastOneHasDiff
    },
    isSaveBtnDisabled () {
      const { pageLoading, updating, canSave } = this
      return pageLoading || updating || !canSave
    },
    oldVariables () {
      const { formsConfigCurrent } = this
      const configWithoutNewVars = _.cloneDeep(this.$data.$originalData.rawFormsConfig || {})

      for (const section in configWithoutNewVars) {
        for (const group in configWithoutNewVars[section]) {
          for (const widget in configWithoutNewVars[section][group]) {
            const fullVariable = formsConfigCurrent.sections
              .find(({ name }) => name === section).groups
              .find(({ name }) => name === group).vars
              .find(({ key }) => key === widget)

            if (fullVariable?.new) configWithoutNewVars[section][group][widget] = null
          }
        }
      }

      return configWithoutNewVars
    },
    quotasEnabled () {
      return this.organization.quotas && this.stack?.quota_enabled
    },
  },
  watch: {
    rawFormsConfig: {
      handler (config) {
        if (_.isEmpty(this.$data.$originalData.rawFormsConfig)) {
          this.$setOriginalData('rawFormsConfig')
        }
      },
      deep: true,
    },
    component: {
      async handler (newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          await this.loadEnvComponent()
        }
      },
      immediate: true,
    },
  },
  destroyed () {
    this.RESET_STACK_STATE()
    this.STOP_FETCH('environmentsConfig')
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_ENV_COMPONENT_CONFIG',
      'GET_PROJECT_PIPELINES',
      'UPDATE_ENV_COMPONENT',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK_CONFIG',
    ]),
    ...mapMutations('organization/project', [
      'CLEAR_PROJ_ERRORS',
      'STOP_FETCH',
    ]),
    ...mapMutations('organization/stack', [
      'SET_STACK',
      'RESET_STACK_STATE',
    ]),
    exitRoute () {
      this.$router.push(this.to)
    },
    getUserConfigsWithDestination (config, array = []) {
      _.entries(config).forEach(([key, value]) => {
        if (!_.isPlainObject(value)) return

        const { content, destination: path } = value
        path
          ? array.push({ content: btoa(content), path })
          : this.getUserConfigsWithDestination(config[key], array)
      })

      return array
    },
    async resetConfig () {
      this.pageLoading = true
      this.setCurrentFormsConfig()
      this.pageLoading = false
    },
    async retrieveEnvComponentFormsConfiguration () {
      if (_.isEmpty(this.projectPipelines)) await this.GET_PROJECT_PIPELINES()
      await this.GET_ENV_COMPONENT_CONFIG({ envCanonical: this.envCanonical, componentCanonical: this.componentCanonical })
      this.setCurrentFormsConfig()
    },
    setConfigurationValidity (env, isValid) {
      this.$set(this.isConfigurationValid, env, isValid)
    },
    setCurrentFormsConfig () {
      const { stackConfig, componentUseCase: useCase } = this
      const configCurrent = _.cloneDeep(stackConfig[useCase].forms)
      if (!configCurrent) {
        this.missingUsecaseErrors = [this.$t('missingUsecaseError', { useCase })]
        return
      }

      configCurrent?.sections.forEach((section) => {
        section.groups.forEach((group) => {
          group.vars = group.vars.map((widget) => {
            const componentConfigValue = _.get(this.formsConfig, [section.name, group.name, widget.key], undefined)
            const isCurrentUndefined = componentConfigValue === undefined

            return _.omit({
              ...widget,
              default: isCurrentUndefined ? widget.default : componentConfigValue,
              new: isCurrentUndefined,
            }, 'current')
          })
        })
      })

      this.$set(this, 'formsConfigCurrent', configCurrent)
    },
    stayOnPage () {
      this.showConfirmCloseDialog = false
      this.to = null
    },
    getUpdatedComponent () {
      return { ...this.component, vars: this.rawFormsConfig }
    },
    async pushComponentConfig () {
      this.updating = true
      this.CLEAR_PROJ_ERRORS('environmentsConfig')
      this.CLEAR_PROJ_ERRORS('forms')

      try {
        const component = this.getUpdatedComponent()
        await this.UPDATE_ENV_COMPONENT({
          envCanonical: this.envCanonical,
          component,
          displaySuccessMessage: true,
          refreshProject: true,
          refreshConfig: true,
        })
        this.$setOriginalData('rawFormsConfig')
        await this.retrieveEnvComponentFormsConfiguration()
      } catch (error) {
        console.error(error)
      } finally {
        this.updating = false
        this.showStackFormsDiff = false
      }
    },
    async loadEnvComponent () {
      if (!this.component) return
      this.$toggle.pageLoading(true)
      if (!_.isEmpty(this.$data.$originalData.rawFormsConfig)) {
        this.rawFormsConfig = {}
        this.$setOriginalData('rawFormsConfig')
      }
      this.missingUsecaseErrors = []
      this.RESET_STACK_STATE()
      const query = [
        this.component?.use_case,
        this.projectCanonical,
        this.envCanonical,
        this.componentCanonical,
      ]
      await this.GET_STACK_CONFIG({ stackRef: this.component.service_catalog.ref, query })
      await this.retrieveEnvComponentFormsConfiguration()
      this.SET_STACK(this.component.service_catalog)
      this.$toggle.pageLoading(false)
      this.to = null
    },
  },
  i18n: {
    messages: {
      en: {
        title: 'Component configuration',
        confirmCloseDialog: {
          actionBtnText: 'Leave',
          cancelBtnText: 'Stay on StackForms',
          text: 'There are unsaved changes, are you sure you want to leave StackForms?',
          title: 'Unsaved changes detected',
        },
        missingUsecaseError: 'No configuration was found for the <b>{useCase}</b> usecase',
        stackFormsConfiguration: 'StackForms configuration',
        stackFormsDiffDialog: {
          actionBtnText: 'Apply changes',
          title: 'Edit StackForms configuration',
        },
        readOnlyNotification: {
          title: 'This is a Read Only view.',
          text: 'You do not have sufficient permissions to configure this component.',
        },
      },
      es: {
        title: 'Configuración del componente',
        confirmCloseDialog: {
          actionBtnText: 'Salir',
          cancelBtnText: 'Mantente en StackForms',
          text: 'Hay cambios sin guardar, ¿está seguro de que quiere abandonar StackForms?',
          title: 'Cambios no guardados detectados',
        },
        missingUsecaseError: 'No se encontró ninguna configuración para el caso de uso <b>{useCase}</b>',
        stackFormsConfiguration: 'Configuración de StackForms',
        stackFormsDiffDialog: {
          actionBtnText: 'Aplicar cambios',
          title: 'Editar la configuración de StackForms',
        },
        readOnlyNotification: {
          title: 'Esta es una vista de sólo lectura.',
          text: 'No tiene permisos suficientes para configurar este componente.',
        },
      },
      fr: {
        title: `Configuration du composant`,
        confirmCloseDialog: {
          actionBtnText: 'Laisser',
          cancelBtnText: 'Restez sur StackForms',
          text: 'Il y a des modifications non enregistrées, êtes-vous sûr de vouloir quitter StackForms ?',
          title: 'Modifications non enregistrées détectées',
        },
        missingUsecaseError: `Aucune configuration n'a été trouvée pour le cas d'utilisation <b>{useCase}</b>`,
        stackFormsConfiguration: 'Configuration de StackForms',
        stackFormsDiffDialog: {
          actionBtnText: 'Appliquer les modifications',
          title: 'Modifier la configuration de StackForms',
        },
        readOnlyNotification: {
          title: `Il s'agit d'une vue en lecture seule.`,
          text: 'Vous ne disposez pas des autorisations suffisantes pour configurer ce composant.',
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.env-component-configuration {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;

  &__stackforms {
    &--margin-correction {
      display: flex;
      flex-grow: 1;
      width: auto;
      max-width: initial;
      margin: 0 -32px 37px;
      border-top: none;
      background-color: cy-get-color("white");
    }
  }

  &__actions {
    position: absolute;
    z-index: 1;
    bottom: -32px;
    left: -32px;
    width: calc(100% + 64px);
    padding: 16px;
    border-top: solid 1px cy-get-color("grey", "light-2");
    background-color: cy-get-color("white");
  }
}

::v-deep .stack-forms {
  min-height: calc(100vh - 276px);
}

::v-deep .stack-forms__group {
  margin-left: 42px;

  > .v-expansion-panel-content__wrap {
    padding-right: 0;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
